import { useMutation, useQueryClient } from "react-query"
import { useGqlClient } from "../client"
import { blockPharmacyProjectGroup } from "../mutations/blockPharmacyProjectGroup"
import { finishPresentations } from "../mutations/finishPresentations"
import { createAppointment } from "../mutations/appointment"
import toast from "../../ui-components/NewToast"

type FinishPresentationProps = {
  dbId: number
  projectId: number
  starttime: string
  timespent: number
  adjustedNumberOfParticipants: number
  rounds: number
}

type CreateAppoinymentProps = {
  pharmacyId: string
  dbId: string
  date: string
  amount: number
  projectIds:  string[]
}

type BlockPharmacyProjectProps = {
  pharmacyId: string
  dbId: number
}

export const useBlockPharmacyProjectGroup = () => {
  const gqlClient = useGqlClient()
  const queryClient = useQueryClient()
  const blockPharmacy = async (variables: BlockPharmacyProjectProps) => await gqlClient.request(blockPharmacyProjectGroup, variables)
  return useMutation(blockPharmacy, {
    onSuccess: () =>  {
      queryClient.refetchQueries('pharmacyProjectGroups') 
      queryClient.refetchQueries('userPharmacyProjectGroups')
      toast.show({ headline: 'Schulung wurde blockiertt.', type: 'success' })
    }, 
     onError: () => {
      toast.show({ headline: 'Etwas ist schief gelaufen.', type: 'error' })
    },
  })
}

  export const useAddFinishPresentations = () => {
    const gqlClient = useGqlClient()
    const queryClient = useQueryClient()
    const addFinishPresentations = async (variables: FinishPresentationProps) => await gqlClient.request(finishPresentations, variables)
    return useMutation(addFinishPresentations, {
      onSuccess: () => {
        queryClient.refetchQueries('userFinishedPresentationGroups')
        queryClient.refetchQueries('userAppointments')
        toast.show({ headline: 'Schulung wurde abgeschlossen.', type: 'success' })
       },
      onError: () => {
        toast.show({ headline: 'Etwas ist schief gelaufen.', type: 'error' })
      },
      })
    }

  export const useAddAppointment = () => {
    const gqlClient = useGqlClient()
    const queryClient = useQueryClient()
    const addAppointment = async (variables: CreateAppoinymentProps) => await gqlClient.request(createAppointment, variables)
    return useMutation(addAppointment, {
      onSuccess: () => {
        queryClient.refetchQueries('userAppointments')
        queryClient.refetchQueries('userPharmacyProjectGroups')
        toast.show({ headline: 'Schulung wurde avisiert.', type: 'success' })
        },
      onError: () => {
        toast.show({ headline: 'Etwas ist schief gelaufen.', type: 'error' })
      },
      })
    }