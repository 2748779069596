import { PharmacyProjectGroup } from '../types/Pharmacy'
import InputLabel from '../ui-components/InputLabel'
import InputMessage, { InputMessageKind } from '../ui-components/InputMessage'
import TextInput, { TextInputKind } from '../ui-components/TextInput'
import { ModalKind } from './Modal'
import Modal from './Modal'
import { Field, Formik } from 'formik'
import React, { useState } from 'react'
import { useModal } from '../contexts/modalContext'
import * as Yup from 'yup'
import Button, { ButtonKind } from "../ui-components/Button"
import { useAddAppointment } from '../graphql/hooks/blockPharmacyProjectGroup'
import { parseISO, format, parse, isBefore } from 'date-fns'
import toast from '../ui-components/NewToast'

interface Props {
  pharmacy: PharmacyProjectGroup
}

const AddAppointmentModal: React.FC<Props> = ({ pharmacy }) => {
  const [pharmacyName] = useState<string>(pharmacy.pharmacy.name)
  const [date, setDate] = useState<string>('')
  const [time, setTime] = useState<string>('')
  const [rounds, setRounds] = useState<string>('')
  const { closeModal } = useModal()
  const { mutate: addAppointment } = useAddAppointment()
  const areNotValidated = (time.length < 4 || date.length < 8 || !rounds.length) ? true : false


  const initialFormValues = {
    name: pharmacyName,
    time: time,
    date: date,
  }

  const today = new Date();

  const ModalSchema = Yup.object().shape({
    time: Yup.string()
      .min(4, 'Zu kurz!')
      .required('Bitte auswählen'),
      date: Yup.string()
      .min(8, 'Zu kurz!')
      .required('Bitte auswählen')
      .test('is-future-month', 'Das ausgewählte Datum darf nicht in einem vergangenen Monat liegen', function (value) {
        if (!value) return true; 
        const selectedDate = parse(value, 'yyyy-MM-dd', new Date()); 
        const isPast = isBefore(selectedDate, new Date(today.getFullYear(), today.getMonth(), 1)); 
        return !isPast;
      }),
  });

  const filteredProjects = pharmacy.projectGroups
  .filter(project => !project.arePresentationsFinished)

  const onFormSubmit = async () => {
    const formatDate = (date: string, time: string) => {

      const dateTimeString = `${date}T${time}:00`
      const parsedDate = parseISO(dateTimeString)
      
      const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss'Z'")
      
      return formattedDate
    }

    const projectIds = filteredProjects.map(item => item.project.id);

    if (areNotValidated) {
      toast.show({
        headline: 'Bitte geben Sie alle Daten ein',
        type: 'error',
      })
      return 
    }

    formatDate(date, time)
    const formattedDateTime = formatDate(date, time)

    const variables = {
      date: formattedDateTime,
      pharmacyId: pharmacy.pharmacy.id,
      dbId: pharmacy.pharmacy.dbId,
      projectIds: projectIds,
      amount: +rounds,
    }

    addAppointment(variables)
    closeModal()
  }

  return (
    <Modal kind={ModalKind.ms} title={'Termin avisieren'} onClose={closeModal}>
      <Formik key="pharmacy-updated-form" initialValues={initialFormValues} enableReinitialize validationSchema={ModalSchema} onSubmit={onFormSubmit}>
        {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }) => (
          <form className="flex flex-wrap w-full">
            <div className="flex flex-col flex-wrap w-full">
              <div className="w-full p-0 sm:p-2">
                <div className="w-full flex flex-col">
                  <div className="px-6 py-0 sm:py-2">
									<InputLabel className={'flex flex-col items-center'}>{'Firmenname'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        type="text"
                        value={pharmacyName}
                        kind={TextInputKind.roundedModal}
                      />
                      {errors.name && touched.name ? <InputMessage kind={InputMessageKind.error}>{errors.name}</InputMessage> : null}
                    </div>
                  </div>
                  <div className='flex flex-col sm:flex-row justify-between'>
                  <div className="w-full px-6 py-2">
									<InputLabel className={'flex flex-col items-center'}>{'Datum'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setDate(event.currentTarget.value)}
                        type="date"
                        value={date}
                        kind={TextInputKind.roundedModal} 
                        name="date"
                      />
                      {errors.date && touched.date ? <InputMessage kind={InputMessageKind.error}>{errors.date}</InputMessage> : null}
                    </div>
                  </div>
                  <div className="w-full px-6 py-2">
                    <InputLabel className={'flex flex-col items-center'}>{'Uhrzeit (24-Stunden-Format)'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <Field
                        className="form-input font-body text-gray-250 block w-full h-10 sm:h-14 rounded-full px-8 py-4 text-xs sm:text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400 sm:text-base sm:leading-5"
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setTime(event.currentTarget.value)}
                        type="time"
                        value={time}
                        kind={TextInputKind.roundedModal}
                      />
                    </div>
                  </div>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="w-full p-0 sm:p-2">
              <div className='flex flex-col sm:flex-row'>
                  <div className="w-full px-6 py-2">
                    <InputLabel className={'flex flex-col items-center'}>{'Schulungsthema'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <TextInput
                        type="text"
                        value={filteredProjects.map((project) => project.project.name).join(', ')}
                        kind={TextInputKind.roundedModal}
                      />
                    </div>
                  </div>
                  <div className="w-full px-6 py-2">
                    <InputLabel className={'flex flex-col items-center'}>{'Anzahl der Durchgänge'}</InputLabel>
                    <div className="mt-1 rounded-md ">
                      <TextInput
                        onChange={(event: React.FormEvent<HTMLInputElement>) => setRounds(event.currentTarget.value)}
                        type="number"
                        value={rounds}
                        kind={TextInputKind.roundedModal}
                      />
                    </div>
                  </div>
              </div>
              </div> 
              <div className={`sticky self-end bottom-0 w-full justify-between p-0 sm:p-4 pt-4 pb-6 place-items-stretch`}>
                    <Button 
                      kind={ButtonKind.primaryModal} 
                      onClick={handleSubmit} 
                      customStyle={'w-full text-xl'}
                    >
                      {'TERMIN AVISIEREN'}
                    </Button>
                  </div>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

export default AddAppointmentModal