import { gql } from "graphql-request"

export const blockPharmacyProjectGroup = gql`
mutation blockPharmacyProjectGroup($pharmacyId: ID!, $dbId: ID!) {
    blockPharmacyProjectGroup(pharmacyId: $pharmacyId, dbId: $dbId) {
      blockedPharmacyProjects {
        ... on KernPharmacyProject {
          dbId
          id
          externalId
        }
        ... on GskPharmacyProject {
          dbId
          id
          externalId
        }
        ... on BayerPharmacyProject {
          dbId
          id
          externalId
        }
        ... on PbPharmacyProject {
          dbId
          id
          externalId
        }
      }
    }
  }
`
